export const cn = {
	"Start Registration": (ctx) => "开始注册",
	"Register your business in Singapore": (ctx) => "在新加坡注册您的公司",
	"Enjoy hassle-free service and responsive support. Register your business 100% online with us today.": (ctx) =>
		"享受无忧的服务和响应迅速的支持。立即向我们 100% 在线注册您的企业。",
	"Start Registration": (ctx) => "开始注册",
	"Save Form Draft": (ctx) => "保存表格草稿",
	"This registration takes approximately 15 minutes. You may save your progress and resume your form application at your convenience.":
		(ctx) => "此注册大约需要 15 分钟, 您可以保存进度并在方便时继续填写表格申请。",
	"This registration takes approximately 15 minutes. Your progress will be saved and you may resume your form application at your convenience.":
		(ctx) => "此注册过程大约需要 15 分钟。您的进度将被保存，您可以在方便时恢复您的表格申请。",

	"Company Details": (ctx) => "公司详情",
	"Members / Shareholders Details": (ctx) => "成员/股东详情",
	"Officers Details": (ctx) => "官员详情",
	Constitution: (ctx) => "宪法",
	"Financial Year End": (ctx) => "财政年度结束",
	Declaration: (ctx) => "宣言",

	/* Form.CorpsecRegNewCoCombine - step 1 */
	"PROPOSED COMPANY NAME": (ctx) => "公司名称",
	"Please provide up to 3 options.": (ctx) => "请提供最多 3 个选项",
	"Company Name (1st choice)": (ctx) => "公司名称（第一选择)",
	"Company Name (2nd choice)": (ctx) => "公司名称（第二选择)",
	"Company Name (3rd choice)": (ctx) => "公司名称（第三选择）",
	"Company name subjects to approval by ACRA": (ctx) => "公司名称须经 ACRA 批准",
	"ISSUED & PAID UP CAPITAL": (ctx) => "已发行及实收资本",
	"What is your base currency": (ctx) => "您的基础货币是什么",
	"Base Currency": (ctx) => "基础货币",
	"Please specify your issued & paid up capital.": (ctx) => "请注明您的已发行资本和实收资本",
	"Capital Amount": (ctx) => "资本金额",
	"If the paid up capital is $5,000 & above (in any currency), to produce either the bank remittance advice or the cheque image for proof of payment by shareholder(s).":
		(ctx) => "如果实收资本为 5,000 美元及以上（任何货币），则需出示银行汇款单或支票图像作为股东付款证明。",
	"MAIN PRINCIPAL ACTIVITIES": (ctx) => "主要业务活动",
	"Primary Activity": (ctx) => "主要公司活动",
	"Secondary Activity": (ctx) => "次要公司活动",
	"REGISTERED ADDRESS": (ctx) => "注册地址",
	"Please state if the registered address is at": (ctx) => "请注明注册地址是否为",
	"The registered address is different.": (ctx) => "注册地址不同",
	"Address 1": (ctx) => "地址第一行",
	"Address 2": (ctx) => "地址第二行",
	"Postal Code": (ctx) => "邮政编码",
	Next: (ctx) => "下一步",

	/* Form.CorpsecRegNewCoCombine - step 2 */
	"State the names of two (2) Directors if member is a corporation. A common seal is required to be affixed in the constitution.":
		(ctx) => "如果成员是公司，请注明两 (2) 名董事的姓名。章程中需要加盖法团印章。",
	"Add Member": (ctx) => "添加会员",
	"Name (as per NRIC/Passport/ACRA)": (ctx) => "姓名（根据身份证/护照/ACRA)",
	"NRIC/Passport/ACRA No.": (ctx) => "身份证/护照/ACRA 号码",
	"Mobile Number": (ctx) => "手机号码",
	"Residential Address": (ctx) => "居住地址",
	"No of Ordinary Shares Held": (ctx) => "持有普通股数量",
	Cancel: (ctx) => "取消",
	Email: (ctx) => "电子邮件",
	"Full Name": (ctx) => "全名",
	"Share  Amount": (ctx) => "股份数量",
	Update: (ctx) => "更新",
	Add: (ctx) => "添加",

	/* Form.CorpsecRegNewCoCombine - step 3 */
	"DIRECTOR DETAILS": (ctx) => "公司董事详细信息",
	"Note: Nominee Director service is available upon request": (ctx) => "注：可根据要求提供名义董事服务",
	"COMPANY SECRETARY": (ctx) => "公司秘书",
	"Unless you specifically inform us otherwise in writing, we will proceed to appoint our in-house officer as default.":
		(ctx) => "除非您以书面形式明确通知我们，否则我们将继续任命我们的内部官员作为默认人员",
	"I understand and agree with the above.": (ctx) => "我理解并同意上述内容。",
	"OTHER DIRECTORSHIPS": (ctx) => "其他董事职务",
	"Please specify the names of companies, that any directors hold directorships of.": (ctx) =>
		"请注明任何董事担任董事职务的公司名称。",
	"Add Director": (ctx) => "添加公司董事",
	"I hereby confirm that the above directors had not been the director of not less than 3 companies which were struck off within 5-year period commencing from 3 January 2014 as a consequence of review exercise by the ACRA.":
		(ctx) =>
			"本人特此确认，上述董事并非不少于 3 家公司的董事，这些公司在自 2014 年 1 月 3 日起的 5 年内因 ACRA 的审查而被除名",
	"I confirm that, according to Section 155A of the Companies Act 1967, none of the above directors’ companies have been struck off the register by ACRA under Section 344 within the last 5 years. The 5-year period starts from the date the 3rd company is struck off.":
		(ctx) =>
			"我确认，根据 1967 年公司法第 155A 条，上述董事的任何公司在过去 5 年内均未根据 ACRA 第 344 条从登记册中删除。 5年期限自第三家公司注销之日起计算。",
	"I understand that if 3 or more companies are struck off by ACRA within 5 years, the directors will be disqualified from acting as directors or managing any company for 5 years.":
	(ctx) =>
		"据我了解，如果5年内有3家或以上公司被ACRA除牌，该董事将被取消担任董事或管理任何公司的资格5年。",
	"I confirm that the information provided is true and correct to the best of my knowledge.":
	(ctx) =>
		"我确认，据我所知，所提供的信息是真实且正确的。",
	"Enclose clear photocopies of identity cards (both sides) / passports for all Directors/Shareholders together with proof of residential address if passports are furnished.":
		(ctx) => "附上所有董事/股东的身份证（双面）/护照的清晰复印件以及居住地址证明（如果提供护照）。",

	/* Form.CorpsecRegNewCoCombine - step 4 */
	"COMPANY CONSTITUION": (ctx) => "公司章程",
	"Companies are incorporated with our standard Constitution, unless otherwise instructed, an additional fee, based on time cost and complexity of job, will be raised for customization of Constitution.":
		(ctx) => "公司按照我们的标准章程成立，除非另有说明，否则将根据时间成本和工作复杂性收取额外费用以定制章程。",
	"I understand and agree with the above.": (ctx) => "我理解并同意上述内容。",

	/* Form.CorpsecRegNewCoCombine - step 5 */
	FYE: (ctx) => "财政年度结束",
	" Please decide on your company financial year end": (ctx) => "请决定贵公司的财政年度结束日期",
	"Deciding on the FYE is very important as it will determine when your corporate filings and taxes are due every year.":
		(ctx) => "决定财政年度非常重要，因为它将决定您每年的公司申报和纳税到期时间。",
	"Select Date": (ctx) => "选择日期",

	/* Form.CorpsecRegNewCoCombine - step 6 */
	"DECLARATION BY APPLICANT": (ctx) => "申请人声明",
	"Please enter your Name, NRIC/Passport number and Email": (ctx) => "请输入您的姓名、身份证/护照号码和电子邮件",
	Name: (ctx) => "姓名",
	"NRIC / Passport No.": (ctx) => "身份证/护照号码",
	Email: (ctx) => "电子邮件",
	I: (ctx) => "我",
	name: (ctx) => "姓名",
	"of NRIC / Passport No.": (ctx) => "身份证/护照号码",
	email: (ctx) => "电子邮件",
	"on behalf of the Board of Directors of the proposed company, hereby declare that:": (ctx) =>
		"代表拟建公司董事会特此声明：",
	"the Director(s) is/are not a bankrupt": (ctx) => "董事并非破产人",
	"the member who is a corporation/LLP, is not under liquidation (if applicable)": (ctx) =>
		"作为公司/有限责任合伙企业的成员未处于清算状态（如果适用）",
	"the particulars and information including documents, furnished by me in respect of this application form are true and correct to the best of my knowledge and belief, and that I have not willfully suppressed any material fact.":
		(ctx) =>
			"据我所知和所信，我就本申请表提供的详细信息和信息（包括文件）均真实、正确，并且我没有故意隐瞒任何重要事实。",
	"Please tick checkbox to declare.": (ctx) => "请勾选复选框进行申报。",

    
    /* miscellaneous */
	"Shareholder Type":(ctx) => "股东类型",
	"Individual":(ctx)=>"个人",
	"Corporate":(ctx)=>"公司",
	
	"Full Name (as in Certificate of Incorporation)": (ctx) => "注册证书上的全名",
	"Registered Office Address": (ctx) => "注册办公地址",
	"Date of Incorporation": (ctx)=> "成立日期",
	"Country of Incorporation": (ctx)=> "成立国家",
	"Company Registration No.": (ctx)=> "公司注册号",
	"Name of Authorised Corporate Representative":(ctx)=>"授权公司代表姓名",

	"Drag and drop files here": (ctx) => "将文件拖放到此处",
	"Submit": (ctx) => "提交",
	"Shares": (ctx) => "公司股份",
	"Identification Number": (ctx) => "身份证号码",
	"Date of Birth": (ctx) => "出生日期",
	"DOB": (ctx) => "出生日期",
	"Mobile": (ctx) => "手机号码",
	"Country": (ctx) => "国家",
	"Do you really want to proceed?": (ctx) => "您真的想继续吗？",
	"Nationality": (ctx) => "国籍",
	"Alternate Address (optional)": (ctx) => "备用地址（可选）",
	"Email Address": (ctx) => "电子邮件地址",
	"Other Positions Held": (ctx) => "担任过的其他职务",
	"Chief Executive Officer/Managing Director/Secretary (if applicable)": (ctx) => "首席执行官/董事总经理/秘书（如果适用）",
	"Add Director": (ctx) => "添加公司董事",
	"Country": (ctx) => "国家",
	"Complete": (ctx) => "完成",
	"Get Link": (ctx) => "获取链接",
	"Save Draft": (ctx) => "保存草稿",
	"We will save your progress and send you a link to continue your form application at your convenience.": (ctx) =>
		"我们将保存您的进度，并向您发送一个链接，以便您在方便时继续填写表格申请。",
	"Enter your email": (ctx) => "输入你的电子邮箱",
	"Save Email": (ctx) => "保存电子邮件",
	'Form submitted successfully': (ctx) => "表单提交成功",
	'Thank You': (ctx) => "谢谢你",
	'Back to Home': (ctx) => "返回首页",
	'Submitting': (ctx) => "正在提交",
	'Application Processing Time.': (ctx) => "申请处理时间",
	'Processing will typically takes 2-3 business days.': (ctx) => "处理通常需要 2-3 个工作日。",
	"Please correct form error(s) in Section 1.":(ctx) => "请更正第 1 节中的表格错误。",
	"Please correct form error(s) in Section 2.":(ctx) => "请更正第 2 节中的表格错误。",
	"Please correct form error(s) in Section 3.":(ctx) => "请更正第 3 节中的表格错误。",
	"Please correct form error(s) in Section 4.":(ctx) => "请更正第 4 节中的表格错误。",
	"Please correct form error(s) in Section 5.":(ctx) => "请更正第 5 节中的表格错误。",
	"Please correct form error(s) in Section 6.":(ctx) => "请更正第 6 节中的表格错误。",

	'Draft saved': (ctx) => "草稿已保存",
	'Email sent': (ctx) => "邮件已发送",
	
	'Click here for help with MSIC description': (ctx)=>'单击此处获取有关 MSIC 描述的帮助'
}
